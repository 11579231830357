import { createTheme, ThemeProvider } from '@mui/material';
import { red } from '@mui/material/colors';
import { setChonkyDefaults } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { KeycloakProvider } from 'react-keycloak-provider';
import App from './App';
import './index.css';
import { keycloakConfig } from './keycloak';
import reportWebVitals from './reportWebVitals';

setChonkyDefaults({ iconComponent: ChonkyIconFA });

// material-ui default theme
const customTheme = createTheme({
  palette: {
    primary: {
      main: '#2e9f64'
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    }
  },
});

const container = document.getElementById('root')

function AppDecorated() {
  return <KeycloakProvider config={keycloakConfig} initOptions={{
    onLoad: 'login-required',
    enableLogging: true,
    checkLoginIframeInterval: 5,
    checkLoginIframe: false,
    silentCheckSsoFallback: true,
  }}>
    <StrictMode>
    <ThemeProvider theme={customTheme}>
      <App />
    </ThemeProvider>
    </StrictMode>
  </KeycloakProvider>

}

ReactDOM.render(
  AppDecorated(),
  container
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
