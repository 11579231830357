import { Grid, Paper } from '@mui/material';
import { ChonkyActions, FileBrowser, FileContextMenu, FileList, FileNavbar, FileToolbar } from 'chonky';
import { useState } from 'react';
import useFetch from "react-fetch-hook";
import { useKeycloakContext } from 'react-keycloak-provider';
import './chonkyCustom.css';
import { EPDData, fileActions, useFileActionHandler, useFiles, useFolderChain } from './common';
import DenseAppBar from './DenseAppBar';
import Loader from './Loader';


function App() {
  const { keycloak, authenticated } = useKeycloakContext()
  const [currentFolderPath, setCurrentFolderPath] = useState<string[]>([]);
  const { data: epdFiles, isLoading } = useFetch<EPDData>("/apiv1/", {

    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + keycloak.token,
    },
    depends: [authenticated, keycloak.token],
  });
  const files = useFiles(epdFiles || {}, currentFolderPath);

  const folderChain = useFolderChain(currentFolderPath);
  const { fileActionHandler, isLoading: isFileActionLoading } = useFileActionHandler(setCurrentFolderPath);

  return (
    <Grid container direction="column" spacing={2} sx={{
      backgroundImage: `URL('img/ce-bg.png')`,
      display: 'flex',
      paddingTop: '1em',
      flexDirection: 'column',
      height: '100%'

    }}>


      <DenseAppBar />

      <Paper elevation={3} sx={{
        marginTop: '5em',
        flexGrow: 1,
        margin: '1em 1.5em',
        backgroundColor: 'red'
        
      }}>

        <FileBrowser
          files={files}
          fileActions={fileActions}
          folderChain={folderChain}
          onFileAction={fileActionHandler}
          defaultFileViewActionId={ChonkyActions.EnableListView.id}
          clearSelectionOnOutsideClick={true}
          disableDragAndDropProvider={true}
          disableDefaultFileActions={true}
          >
          <FileNavbar />
          <FileToolbar />
          {(isLoading || isFileActionLoading) && <Loader />}
          {!isLoading && <FileList />}
          <FileContextMenu />
        </FileBrowser>
      </Paper>

    </Grid>

  );
}

export default App;
