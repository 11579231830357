
import { ChonkyActions, ChonkyFileActionData, FileArray } from 'chonky';
import { useCallback, useMemo, useState } from 'react';
import { useKeycloakContext } from "react-keycloak-provider";

const PATH_SEPARATOR = "@@@";

export interface EPDData {
  [companyName: string]: {
    [plantName: string]: {
      [epdFile: string]: {
        size: number,
        creation: string
      }
    }
  }
}

export const useFiles = (epdFiles: EPDData, currentFolderPath: string[]): FileArray => {
  return useMemo(() => {
    let files: FileArray = [];
    if (currentFolderPath.length === 0 || (currentFolderPath.length === 1 && currentFolderPath[0] === 'root')) {
      const companies = Object.keys(epdFiles);
      files = companies.map(companyFolders => (
        {
          id: [companyFolders].join(PATH_SEPARATOR),
          name: companyFolders,
          isDir: true,
          childrenCount: Object.keys(epdFiles[companyFolders]).length
        }
      )).filter(companies => companies.childrenCount > 0)
    } else if (currentFolderPath.length === 1) {
      const companyFolder = epdFiles[currentFolderPath[0]];
      const plants = Object.keys(companyFolder);
      files = plants.map(plantFolders => (
        {
          id: [...currentFolderPath, plantFolders].join(PATH_SEPARATOR),
          name: plantFolders,
          isDir: true,
          childrenCount: Object.keys(companyFolder[plantFolders]).length,
        }
      )).filter(plants => plants.childrenCount > 0)
    } else {
      const plantFolder = epdFiles[currentFolderPath[0]][currentFolderPath[1]];
      const epdFilesData = Object.keys(plantFolder);
      files = epdFilesData.map(plantEpdFile => (
        {
          id: [...currentFolderPath, plantEpdFile].join(PATH_SEPARATOR),
          name: plantEpdFile,
          isDir: false,
          size: plantFolder[plantEpdFile].size,
          modDate: plantFolder[plantEpdFile].creation,
        }
      ))
    }
    return files;
  }, [epdFiles, currentFolderPath]);
};

export const useFolderChain = (currentFolderPath: string[]): FileArray => {
  return useMemo(() => {
    const root: FileArray = [
      {
        id: 'root',
        name: 'EPDs',
        isDir: true,
      }
    ];
    if (currentFolderPath.length === 0 || (currentFolderPath.length === 1 && currentFolderPath[0] === 'root')) {
      return root;
    }
    const chain: FileArray = currentFolderPath.map(f => ({
      id: f,
      name: f,
      isDir: true,
    }));
    return root.concat(chain);

  }, [currentFolderPath]);
};


export const fileActions = [ChonkyActions.SelectAllFiles, ChonkyActions.ClearSelection, ChonkyActions.DownloadFiles, ChonkyActions.OpenSelection, ChonkyActions.EnableListView, ChonkyActions.EnableGridView].map((action: any) => {
  return Object.assign({}, action, {button: Object.assign({}, action.button, {group: undefined})} )
})

export const useFileActionHandler = (
  setCurrentFolderPath: (currentFolderPath: string[]) => void
) => {
  const { keycloak } = useKeycloakContext();
  const [isLoading, setLoading] = useState(false);
  const fileActionHandler = useCallback(
    (data: ChonkyFileActionData) => {
      if (data.id === ChonkyActions.OpenFiles.id) {
        const { targetFile, files } = data.payload;
        const fileToOpen = targetFile ?? files[0];
        if (fileToOpen && fileToOpen.isDir) {
          setCurrentFolderPath(fileToOpen.id.split(PATH_SEPARATOR));
          return;
        } else {
          for (const fileToOpen of files) {
            const filePath = fileToOpen.id.replaceAll(PATH_SEPARATOR, '/');
            window.open(encodeURI(`/apiv1/${filePath}?disposition=inline&token=${keycloak.token}`), '_blank', 'fullscreen=yes');

          }
        }
      } else if (data.id === ChonkyActions.DownloadFiles.id) {
        const { selectedFilesForAction: files } = data.state

        if (files.length > 1) {
          setLoading(true)
          fetch("/apiv1/zip", {
            method: 'POST',
            body: JSON.stringify({ files: files.map((f: any) => (f.id.replaceAll(PATH_SEPARATOR, '/'))) }),
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + keycloak.token,
            }
          })
            .then(response => response.blob())
            .then(fileBlob => {
              setLoading(false)
              let csvURL = window.URL.createObjectURL(fileBlob);
              let tempLink = document.createElement('a');
              tempLink.href = csvURL;
              tempLink.setAttribute('download', 'EPDs.zip');
              tempLink.click();
            });
        } else {
          const fileToOpen = files[0];
          const filePath = fileToOpen.id.replaceAll(PATH_SEPARATOR, '/');
          window.open(encodeURI(`/apiv1/${filePath}?disposition=attachment&token=${keycloak.token}`), '_blank', 'fullscreen=yes');

        }

      }
    },
    [setCurrentFolderPath, keycloak.token]
  );
  return { fileActionHandler, isLoading }
};